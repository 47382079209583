import React, { useEffect, useState } from 'react';
import { Button, TextField, Grid, InputLabel, Typography, Box } from '@mui/material';
import ReusableModal from '../routemodal/ResuableModal';
import DropDown from 'components/dropdown/DropDown';
import { showSuccess, showWarning } from 'components/nudges/Alert';
import { useDispatch, useSelector } from 'react-redux';
import { CustomerAddRiderData } from 'store/reducers/premiumPolicy';
import useGetCountriesList from 'modules/packages & premium/hooks/useGetCountriesList';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
const AddRiderContent = ({ isOpen, onClose, selectedRow, isEditMode }) => {
  const dispatch = useDispatch();
  const [firstName, setFirstName] = useState(selectedRow ? selectedRow.firstName : '');
  const [lastName, setLastName] = useState(selectedRow ? selectedRow.lastName : '');
  const [dob, setDob] = useState(selectedRow ? selectedRow.dob : '');
  const [gender, setGender] = useState(selectedRow ? selectedRow.gender : '');
  const [license, setLicense] = useState(selectedRow ? selectedRow?.license : '');
  const [nric, setNric] = useState(selectedRow ? selectedRow?.nric : '');
  const [contactNumber, setContactNumber] = useState(selectedRow ? selectedRow?.contactNumber : '');
  const [email, setEmail] = useState(selectedRow ? selectedRow?.email : '');
  const [nationality, setNationality] = useState(selectedRow ? selectedRow?.nationality : '');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(selectedRow ? selectedRow.isCheckboxChecked : false);
  const [isRiderCheckboxChecked, setIsRiderCheckboxChecked] = useState(selectedRow ? selectedRow.isRiderCheckboxChecked : false);
  const [isCheckboxDisabled, setIsCheckboxDisabled] = useState(false);

  const { CustomerriderDataSection } = useSelector((state) => state.persistedReducer.premiumPolicy.otherState);
  const { otherState } = useSelector((state) => state.persistedReducer.premiumPolicy);

  const key = selectedRow?.id || new Date().toString();

  const getCountriesListData = useGetCountriesList();

  useEffect(() => {
    if (selectedRow?.isCheckboxChecked) {
      setIsCheckboxDisabled(false);
    } else {
      let temp = Object.values(CustomerriderDataSection)?.map((rider) => rider?.isCheckboxChecked);
      if (temp?.includes(true)) setIsCheckboxDisabled(true);
    }

    let temp = Object.values(CustomerriderDataSection)?.map((rider) => rider?.isCheckboxChecked);
    if (temp?.includes(true)) setIsCheckboxDisabled(true);
  }, []);

  const handleChange = (event) => {
    setIsCheckboxChecked(event.target.checked);
    if (event.target.checked) {
      setIsRiderCheckboxChecked(false);
    }
  };

  const calculateAge = (dob) => {
    const currentDate = new Date();
    const ageInMilliseconds = currentDate - new Date(dob);
    const ageInYears = ageInMilliseconds / (1000 * 60 * 60 * 24 * 365.25);
    return Math.floor(ageInYears);
  };
  const calculateDuration = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const yearsDifference = end.getFullYear() - start.getFullYear();
    const monthsDifference = end.getMonth() - start.getMonth();
    return yearsDifference * 12 + monthsDifference;
  };
  const totalExperienceMonths = calculateDuration(license, new Date());
  const experienceString = `${totalExperienceMonths} month${totalExperienceMonths !== 1 ? 's' : ''}`;

  // Prefill user data from localStorage
  useEffect(() => {
    if (!isEditMode && isOpen && Object.keys(CustomerriderDataSection).length === 0) {
      // Only when adding a new rider
      const user = JSON.parse(localStorage.getItem('@user'));
      if (user) {
        setFirstName(user.firstName || '');
        setLastName(user.lastName || '');
        setDob(user.dob || ''); // Ensure DOB format is correct for date input
        setGender(user.gender || '');
        setLicense(user.license || ''); // Assuming user has license info
        setNric(user.nricNo || ''); // Assuming user has license info
        setContactNumber(user.contactNumber || ''); // Assuming user has license info
        setEmail(user.finance_Email__c || ''); // Assuming user has license info
      }
    }
  }, [isOpen, isEditMode]);

  const validateEmail = (email) => {
    // const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(?:\.[a-zA-Z]{2,})?$/;
    return emailRegex.test(email) && !email.includes('..');
  };

  const handleAddRider = () => {
    let { claimamount, isFoodParcel, claimcount, ownerAge } = otherState;
    const currentDate = new Date();
    const age = calculateAge(dob);
    const dobYear = new Date(dob).getFullYear();
    const riders = Object.values(CustomerriderDataSection);
    const dobDate = new Date(dob);
    const licenseDate = new Date(license);
    const minLicenseDate = new Date(dobDate);
    minLicenseDate.setFullYear(minLicenseDate.getFullYear() + 18);

    if (!firstName || !lastName || !dob || !gender || !license || !nationality || !email || !nric || !contactNumber) {
      showWarning('Please fill in all required fields');
      return;
    }

    if (riders.length >= 0) {
      const existingOwner = riders.some((rider) => rider?.isCheckboxChecked);
      if (!existingOwner && !isCheckboxChecked) {
        showWarning('First rider must be selected as the owner');
        return;
      }
    }
    if (age < 18) {
      showWarning('Age must be 18 or more');
      return;
    }
    if (dobYear <= 1900) {
      showWarning('Please enter a valid date of birth ');
      return;
    }
    if (age > 75 && dobYear > 1900) {
      showWarning('Quotation could not be generated due to age validation. Please contact ANDA for further assistance.');
      return;
    }
    if (!email && isCheckboxChecked) {
      showWarning('Please fill email address!');
      return false;
    }
    if (email && !validateEmail(email)) {
      showWarning('Please enter a valid email address');
      return false;
    }

    // const dobDate = new Date(dob);
    // const licenseDate = new Date(license);
    // const minLicenseDate = new Date(dobDate);
    // minLicenseDate.setFullYear(minLicenseDate.getFullYear() + 18);
    if (licenseDate < minLicenseDate) {
      showWarning("The driver's license date must be at least 18 years after date of birth.");
      return;
    }

    if (!license && !isCheckboxChecked) {
      showWarning('Please fill Driving License Date!');
      return;
    }

    if (dobDate >= licenseDate) {
      showWarning('Date of birth must be earlier than the driving license issue date');
      return;
    }

    if (!license && isRiderCheckboxChecked && isCheckboxChecked) {
      showWarning('Please fill in all required fields');
      return;
    }

    const riderData = {
      id: key,
      firstName,
      lastName,
      dob,
      license,
      age: calculateAge(dob),
      gender,
      experience: experienceString,
      isEditMode,
      nric,
      email,
      contactNumber,
      nationality,
      isCheckboxChecked,
      isRiderCheckboxChecked,
      isCheckboxDisabled
    };

    dispatch(CustomerAddRiderData([key, riderData]));
    showSuccess(isEditMode ? 'Rider edited successfully' : 'Rider added successfully');
    onClose();
  };

  const options = [
    { label: 'Male', id: 1 },
    { label: 'Female', id: 2 }
  ];
  const handleLicenseDate = (newValue) => {
    const currentDate = new Date();
    const selectedDate = new Date(newValue);
    if (selectedDate > currentDate) {
      newValue = currentDate.toISOString().split('T')[0];
    }
    setLicense(newValue);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box borderBottom={1} borderColor="grey.300">
            <Typography
              style={{
                color: '#000',
                fontFamily: 'Medium',
                display: 'flex',
                justifyContent: 'flex-start',
                marginBottom: '10px',
                fontSize: '16px',
                fontWeight: '600'
              }}
            >
              {isEditMode ? 'Edit Rider' : 'Add Rider'}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px', marginLeft: '2px' }}>First Name</InputLabel>
            <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
          </div>
          <TextField
            size="small"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            required
            onClick={(e) => e.stopPropagation()}
            // disabled={Object.keys(CustomerriderDataSection).length === 0 && (isCheckboxChecked || !isEditMode)}
            disabled={Object.keys(CustomerriderDataSection).length === 0 || (isEditMode && (isCheckboxChecked || !isEditMode))}
            inputProps={{
              style: {
                fontSize: '13px' // Change the font size of the input text
                // marginLeft: '2px'
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px', marginLeft: '2px' }}>Last Name</InputLabel>
            <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
          </div>
          <TextField
            size="small"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            required
            onClick={(e) => e.stopPropagation()}
            disabled={Object.keys(CustomerriderDataSection).length === 0 || (isEditMode && (isCheckboxChecked || !isEditMode))}
            inputProps={{
              style: {
                fontSize: '13px' // Change the font size of the input text
                // marginLeft: '2px'
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px', marginLeft: '2px' }}>Date of Birth</InputLabel>
            <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
          </div>
          <TextField
            type="date"
            size="small"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            required
            onClick={(e) => e.stopPropagation()}
            inputProps={{
              max: new Date().toISOString().split('T')[0],
              style: {
                fontSize: '13px' // Change the font size of the input text
                // marginLeft: '2px'
              }
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} className="inputContainer">
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px', marginLeft: '2px' }}>
              Driving License Date
            </InputLabel>
            <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
          </div>
          <TextField
            type="date"
            size="small"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            className="commonInput"
            style={{ marginTop: 3 }}
            value={license}
            onChange={(e) => handleLicenseDate(e.target.value)}
            required
            onClick={(e) => e.stopPropagation()}
            inputProps={{
              max: new Date().toISOString().split('T')[0],
              style: {
                fontSize: '13px' // Change the font size of the input text
                // marginLeft: '2px'
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px', marginLeft: '2px' }}>Gender</InputLabel>
            <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
          </div>
          <DropDown
            marginTop={'1px'}
            width={'100%'}
            heightsss={'37px'}
            height={'40px'}
            options={options}
            placeholder="Select gender"
            value={gender}
            onValueChange={(i) => {
              setGender(i.label);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px', marginLeft: '2px' }}>Nationality</InputLabel>
            <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
          </div>

          <DropDown
            marginTop={'1px'}
            width={'100%'}
            heightsss={'37px'}
            height={'40px'}
            placeholder="Nationality"
            options={getCountriesListData}
            onValueChange={(i) => {
              setNationality(i.label);
            }}
            value={nationality}
            onClick={(e) => e.stopPropagation()}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px', marginLeft: '2px' }}>NRIC</InputLabel>
            <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
          </div>
          <TextField
            size="small"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
            value={nric}
            onChange={(e) => setNric(e.target.value)}
            required
            onClick={(e) => e.stopPropagation()}
            // disabled={(Object.keys(CustomerriderDataSection).length === 0 || isEditMode) && isCheckboxChecked}
            disabled={Object.keys(CustomerriderDataSection).length === 0 || (isEditMode && (isCheckboxChecked || !isEditMode))}
            inputProps={{
              style: {
                fontSize: '13px' // Change the font size of the input text
                // marginLeft: '2px'
              }
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px', marginLeft: '2px' }}>Contact Number</InputLabel>
            <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
          </div>
          <TextField
            size="small"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
            value={contactNumber}
            onChange={(e) => setContactNumber(e.target.value)}
            required
            onClick={(e) => e.stopPropagation()}
            inputProps={{
              style: {
                fontSize: '13px' // Change the font size of the input text
                // marginLeft: '2px'
              }
            }}
            // disabled={Object.keys(CustomerriderDataSection).length === 0}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <InputLabel style={{ fontFamily: 'Medium', fontSize: '13px', marginTop: '5px', marginLeft: '2px' }}>Email Address</InputLabel>
            <InputLabel style={{ color: 'red', fontSize: '20px', display: 'flex', marginLeft: '2px' }}>*</InputLabel>
          </div>
          <TextField
            size="small"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            style={{ backgroundColor: '#F8F8F8', width: '100%', margin: '2px', padding: '5px', borderRadius: '8px' }}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
            onClick={(e) => e.stopPropagation()}
            // disabled={(Object.keys(CustomerriderDataSection).length === 0 || isEditMode) && isCheckboxChecked}
            disabled={Object.keys(CustomerriderDataSection).length === 0 || (isEditMode && (isCheckboxChecked || !isEditMode))}
            inputProps={{
              style: {
                fontSize: '13px' // Change the font size of the input text
                // marginLeft: '2px'
              }
            }}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} style={{ display: 'flex' }}>
        <Grid item xs={4} sm={4}>
          <FormControlLabel
            style={{ fontFamily: 'Medium', fontSize: '13px', justifyContent: 'start', display: 'flex' }}
            control={<Checkbox checked={isCheckboxChecked} onChange={(e) => handleChange(e)} disabled={isCheckboxDisabled} />}
            label="Owner"
            sx={{
              '& .MuiFormControlLabel-label': {
                fontFamily: 'Medium',
                fontSize: '13px' // Override font size here
              }
            }}
          />
        </Grid>
        <Grid item xs={8} sm={8}>
          {isCheckboxChecked && (
            <FormControlLabel
              style={{ fontFamily: 'Medium', fontSize: '13px', justifyContent: 'start', display: 'flex', fontSize: '13px' }}
              control={
                <Checkbox
                  checked={isRiderCheckboxChecked}
                  onChange={(e) => {
                    setIsRiderCheckboxChecked(e.target.checked);
                  }}
                />
              }
              label="Insured Person Riding"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontFamily: 'Medium',
                  fontSize: '13px' // Override font size here
                }
              }}
            />
          )}
        </Grid>
      </Grid>

      <Grid container justifyContent="flex-start" mt={2}>
        <Button
          size="small"
          variant="contained"
          style={{
            borderRadius: '8px',
            color: '#fff',
            backgroundColor: '#002d8c',
            minWidth: '100px',
            marginRight: '10px',
            padding: '8px 16px',
            fontFamily: 'Medium',
            fontSize: '13px'
          }}
          onClick={handleAddRider}
        >
          {isEditMode ? 'Edit Rider' : 'Add Rider'}
        </Button>
        <Button
          size="small"
          variant="outlined"
          style={{
            borderRadius: '8px',
            color: '#002d8c',
            backgroundColor: '#ffffff',
            minWidth: '100px',
            marginRight: '10px',
            padding: '8px 16px',
            fontFamily: 'Medium',
            border: '1px solid #002d8c',
            fontSize: '13px'
          }}
          onClick={onClose}
        >
          Cancel
        </Button>
      </Grid>
    </>
  );
};

const AddRiderModal = ({ isOpen, onClose, selectedRow }) => {
  const isEditMode = !!selectedRow;
  return (
    <ReusableModal
      isOpen={isOpen}
      onClose={onClose}
      ModalContent={<AddRiderContent isOpen={isOpen} onClose={onClose} selectedRow={selectedRow} isEditMode={isEditMode} />}
      width="400px"
      height="auto"
      borderRadius="10px"
      padding="20px"
      showCloseButton={false}
    />
  );
};

export default AddRiderModal;
