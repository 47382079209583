import AddRiderModal from 'components/modals/contentmodal/AddRiderContent';
import RiderSectionTable from 'modules/packages & premium/components/RiderSectionTable';
import React, { useState, useEffect } from 'react';
import { Grid, InputLabel, Button } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useSelector, useDispatch } from 'react-redux';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { clearCustomerRiderData, CustomerAddRiderData, removeOtherIndividualState2 } from 'store/reducers/premiumPolicy';
import { showDanger, showSuccess, showWarning } from 'components/nudges/Alert';
import CustomerLoginModal from 'modules/customer/corporate/customerlandingpage/components/CustomerLoginModal';
import CustomerOtpModal from 'modules/customer/customerOTP/CustomerOtpModal';
import CustomerResetPassword from 'modules/customer/customerpasswordreset/CustomerResetPassword';
import OTPResetModal from 'components/modals/contentmodal/OTPResetModal';
import { API } from 'api/API';
import useApiCall from 'api/useApiCall';
import useNetworkStatus from 'hooks/useNetworkStatus';

const AdditionalRiderDetails = () => {
  const { CustomerriderDataSection } = useSelector((state) => state.persistedReducer.premiumPolicy.otherState);
  const dispatch = useDispatch();
  const { makeRequestWithoutLogin } = useApiCall();
  const network = useNetworkStatus();
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [data, setData] = useState([]);
  const [show, setShow] = useState(false);
  const [resetShow, setResetShow] = useState(false);
  const [otpshow, setOtpShow] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [userId, setUserId] = useState('');
  const [emailId, setEmailId] = useState('');
  const hasRiders = data && data?.length > 0;
  const handleClickOnEdit = (rider) => {
    setSelectedRow(rider);
    setOpen(true);
  };

  const handleDeleteRiders = () => {
    dispatch(clearCustomerRiderData());
  };

  // const handleLoginClose = () => {
  //   setLoggedInUser(false);
  //   const user = localStorage.getItem('@user');
  //   if (user) {
  //     setLoggedInUser(false);
  //   } else {
  //     setLoggedInUser(true);
  //   }
  // };

  const handleDelete = (id) => {
    try {
      dispatch(removeOtherIndividualState2(id));
    } catch (e) {
      console.error(e);
    }
  };

  const handleOpen = () => {
    let loggedInUser = localStorage.getItem('@user');
    if (!loggedInUser) {
      setShow(true);
    } else {
      if (Object.keys(CustomerriderDataSection).length >= 3) {
        showWarning('You can not add more than 2 Riders in a policy');
        return;
      }
      setSelectedRow(null);
      setOpen(true);
    }
    // if (Object.keys(CustomerriderDataSection).length >= 2) {
    //   showWarning('You can not add more than 2 Riders in a policy');
    //   return;
    // }
    // setSelectedRow(null);
    // setOpen(true);
  };
  const handleClose = () => {
    setShow(false);
    setOpen(false);
  };

  const handleResetPasswordModalOpen = () => {
    setResetShow(true);
    setShow(false);
  };

  const handleCloseResetModal = () => {
    setIsOpen(false);
    setShow(true);
  };

  const handleOTP = ({ OTP, UserId, emailId }) => {
    setEmailId(emailId);
    setUserId(UserId);
    setResetShow(false);
    setOtpShow(true);
  };

  const handleVerifyOtp = () => {
    setOtpShow(false);
    setIsOpen(true);
  };

  const handleClickOnResendOTP = async () => {
    if (network) {
      try {
        let params = {
          UserId: userId,
          emailId: emailId
        };
        let res = await makeRequestWithoutLogin({ method: 'POST', url: API.PasswordChangeOTPSent, params });
        if (res?.code == 200) {
          showSuccess('OTP sent successfully');
        } else {
          showWarning(res?.message);
        }
      } catch (e) {
        showDanger(`${e}`);
      }
    } else {
      showDanger('No internet');
    }
  };

  const convertMonthsToYearsMonths = (total) => {
    let totalMonths = parseInt(total);
    const years = Math.floor(totalMonths / 12); // Calculate full years
    const months = totalMonths % 12; // Calculate remaining months
    let result = '';

    if (years > 0) {
      result += `${years} year${years > 1 ? 's' : ''}`; // Add 's' if years > 1
    }

    if (months > 0) {
      if (years > 0) result += ' '; // Add space if both years and months are present
      result += `${months} month${months > 1 ? 's' : ''}`; // Add 's' if months > 1
    }

    return result || '0 months'; // Default to '0 months' if no experience
  };

  useEffect(() => {
    const rows = Object?.values(CustomerriderDataSection)?.map((rider, index) => ({
      sr: index + 1,
      id: rider?.id,
      RiderName: `${rider?.firstName} ${rider?.lastName}`,
      age: rider?.age,
      dob: rider?.dob,
      gender: rider?.gender,
      nric: rider?.nric,
      nationality: rider?.nationality,
      contactNumber: rider?.contactNumber,
      experience: convertMonthsToYearsMonths(rider?.experience),
      Action: (
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <Button
            variant="contained"
            style={{
              backgroundColor: /* index === 0 ? '#ccc' :  */ '#4cb748',
              color: '#fff',
              borderRadius: 20,
              fontSize: '13px',
              cursor: /* index === 0 ? 'not-allowed' : */ 'pointer',
              width: '55px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '3px',
              fontFamily: 'Medium'
            }}
            // disabled={index === 0}
            onClick={() => handleClickOnEdit(rider)}
          >
            <EditIcon style={{ fontSize: '15px', marginRight: '2.5px' }} />
            Edit
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor: index === 0 ? '#ccc' : '#0174BF',
              color: '#fff',
              borderRadius: 20,
              fontSize: '13px',
              cursor: index === 0 ? 'not-allowed' : 'pointer',
              width: '75px',
              display: 'flex',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              padding: '3px',
              fontFamily: 'Medium',
              marginLeft: '10px'
            }}
            disabled={index === 0}
            onClick={() => handleDelete(rider.id)}
          >
            <DeleteOutlineOutlinedIcon style={{ fontSize: '15px' }} />
            Delete
          </Button>
        </div>
      )
    }));
    setData(rows);
  }, [CustomerriderDataSection]);

  return (
    <>
      <Grid container spacing={2} mt={1} alignItems="center">
        <Grid item xs={4} lg={8} md={8} sm={6}>
          <InputLabel style={{ fontFamily: 'Medium', fontSize: '16px', color: '#000', fontWeight: 600 }}>Riding Details</InputLabel>
        </Grid>

        <Grid item xs={8} lg={4} md={4} sm={6} textAlign="right">
          <Button
            size="small"
            onClick={handleDeleteRiders}
            sx={{
              backgroundColor: '#002D8C',
              color: 'white',
              fontFamily: 'Medium',
              borderRadius: '8px',
              // width: '100px',
              // height: '35px',
              padding: '2%',
              marginRight: '1%',
              '&:hover': {
                backgroundColor: '#002D8C'
              }
            }}
          >
            Delete Rider & Owner
          </Button>
          <Button
            size="small"
            onClick={handleOpen}
            startIcon={<AddIcon />}
            sx={{
              backgroundColor: '#00C7B1',
              color: 'white',
              fontFamily: 'Medium',
              borderRadius: '8px',
              // width: '100px',
              // height: '35px',
              padding: '2%',
              '&:hover': {
                backgroundColor: '#00B3A0'
              }
            }}
          >
            Add Rider
          </Button>
          <AddRiderModal isOpen={open} onClose={handleClose} selectedRow={selectedRow} />
        </Grid>
        {hasRiders ? (
          <RiderSectionTable columns={MotorAddRiderTableColumns} rows={data} />
        ) : (
          <RiderSectionTable columns={MotorAddRiderTableColumns} rows={[]} />
        )}
        {!hasRiders && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
              fontSize: '16px',
              color: '#000',
              marginTop: '14px'
            }}
          >
            Rider list is empty. Kindly add a rider to continue.
          </div>
        )}
      </Grid>
      <CustomerLoginModal
        isOpen={show}
        setShow={setShow}
        onClose={handleClose}
        handleResetPasswordModalOpen={handleResetPasswordModalOpen}
      />
      <CustomerResetPassword isOpen={resetShow} onClose={() => setResetShow(false)} handleOTP={handleOTP} />
      <CustomerOtpModal
        isOpen={otpshow}
        onClose={() => setOtpShow(false)}
        handleVerifyOtp={handleVerifyOtp}
        handleClickOnResendOTP={handleClickOnResendOTP}
      />
      <OTPResetModal isOpen={isOpen} handleCloseModal={handleCloseResetModal} />
    </>
  );
};

export default AdditionalRiderDetails;
const MotorAddRiderTableColumns = [
  { id: 'sr', label: 'Sr.', minWidth: 40, align: 'center' },
  { id: 'RiderName', label: 'Rider Name', minWidth: 50, align: 'center' },
  { id: 'gender', label: 'Gender', align: 'center' },
  { id: 'dob', label: 'DOB', align: 'center' },
  { id: 'age', label: 'Age', align: 'center' },
  { id: 'nric', label: 'NRIC', align: 'center' },
  { id: 'nationality', label: 'Nationality', align: 'center' },
  { id: 'contactNumber', label: 'Contact Number', align: 'center' },
  { id: 'experience', label: 'Riding Experience', align: 'center' },
  { id: 'Action', label: 'Options', align: 'left' }
];
